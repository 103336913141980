import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Ggbrewery = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #B9A113, #D8BB15, #ECCC16, #D8BB15, #B9A113 )",
        icons: "#f8df10",
        navClass: "gg",
      }}
      seo={{
        title: "GG Brewery",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/gg_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/gg-brewery/",
      }}
    >
      <PortfolioHeader name="gg" height="295" />
      <section className="container-fluid gg_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>GG Brewery</h1>
              <ul>
                <li>Logo browaru</li>
                <li>Logo piw</li>
                <li>Projekty etykiet</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Good Game Brewery warzy piwo dla graczy. Naszym zadaniem było
                zaprojektowanie całej marki od podstaw. Zaprojektowaliśmy logo
                browaru (Good Game Brewery), a także logo i etykiety dla każdego
                z 4 piw (Mid or Feed, Clutchmeister, Battle Royale i Runiczny
                Młot).
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid gg_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/gg_main_2.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/gg_main_3.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 73 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 66 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 66 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 81 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Yellow CMYK</p>
                    <h5>
                      <CountUp start={0} duration={3} end={isVisible ? 5 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 6 : 0} />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 99 : 0}
                      />
                      <span>0</span>
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 19 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 20 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 19 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 248 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 223 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 16 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>HEX</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 131413 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>f8df10</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gg_section_4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="m-auto px-4 text">
            <h4>Mid or Feed</h4>
            <span>Pale ale</span>
            <p className="mb-5">
              Nazwa, logo oraz etykieta nawiązują do najpopularniejszej gry
              MOBA: League of Legends. W projekcie etykiety można dopatrzeć się
              nawiązania do najsilniejszego potwora z Summoner's Rift, Barona
              Nashora.
            </p>

            <h4>Clutchmeister</h4>
            <span>Pils</span>
            <p>
              Nazwa, logo oraz etykieta nawiązują do najpopularniejszej gry FPS;
              CSGO. Na etykiecie można dopatrzeć się nawiązania do oddziału
              antyterrorystów szturmujących siedzibę terrorystów.
            </p>
          </div>
          <div className="img-1">
            <img
              src={require("../../assets/img/portfolio/gg_main_4.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="gg_section_5">
        <div className="d-flex justify-content-between">
          <div className="img-1">
            <img
              src={require("../../assets/img/portfolio/gg_main_5.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="mr-auto px-4 text">
            <h4>Runiczny młot</h4>
            <span>Dunkelweizen</span>
            <p className="mb-5">
              To iście fantastyczne piwo na którego etykiecie można dojrzeć
              krasnoludzką fortecę Karaz Grong. Runiczny Młot to zdecydowanie
              ulubione piwo króla Thorgina.
            </p>

            <h4>Battle Royale</h4>
            <span>American Pale Ale</span>
            <p>
              Nazwa, logo oraz etykieta nawiązują do niezwykle popularnych gier
              takich jak Fortnite czy PLAYERUNKNOWN'S BATTLEGROUNDS. Na
              etykiecie piwa można dojrzeć spadochroniarzy lądujących na wyspie
              na której odbędzie się walka, którą przetrwa tylko jeden z nich.
            </p>
          </div>
        </div>
      </section>
      <section className="gg_section_6">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="inner">
              <h4>Strona internetowa</h4>
              <p>
                Byliśmy również odpowiedzialni za stworzenie strony internetowej
                tego niezwykłego browaru! GL i GG!
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/gg_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gg_section_8">
        <img
          src={require("../../assets/img/portfolio/gg_main_8.png")}
          className="img-fluid"
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Ggbrewery;
